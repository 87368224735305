<template>
  <SlotProductFrame v-if="item">
    <div
      class="shop-product-card__inner"
      @click="goToProduct()"
      @keypress="goToProduct()"
    >
      <ShopProductCardImage v-if="computedComponents.hasImage" />
      <ShopProductCardContent />

      <div class="shop-product-card__flags">
        <AtomFlag
          v-if="!item.isSendable && item.type === 'product'"
          :text="useTranslation('general', 'click_and_collect')"
          icon="click-n-collect-flag"
          fill="white"
        />

        <AtomFlag
          v-if="item.isBundle"
          :text="useTranslation('general', 'bundle')"
        />

        <AtomFlag
          v-if="item.type === 'tip'"
          :text="useTranslation('general', 'tipp')"
        />

        <AtomFlag
          v-if="item.type === 'blog'"
          :text="useTranslation('general', 'blog')"
        />

        <AtomFlag
          v-if="item.type === 'consultation'"
          :text="useTranslation('general', 'consultation')"
        />

        <AtomFlag
          v-if="item.type === 'event'"
          :text="useTranslation('general', 'event')"
        />
      </div>
    </div>

    <ClientOnly>
      <ShopProductControls
        :has-bookmark-icon="computedIcons.hasBookmarkIcon"
        :has-calendar-icon="computedIcons.hasCalendarIcon"
        :has-loved-icon="computedIcons.hasLovedIcon"
        :has-helped-icon="computedIcons.hasHelpedIcon"
        :has-cart-icon="computedIcons.hasCartIcon"
      />
    </ClientOnly>
  </SlotProductFrame>
</template>

<script setup>
const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
});

/*
    Provide Values
*/
const { item } = { ...props };
const computedVariant = computed(() => {
    if (item.type === 'tip') return 'tipp';
    if (item.type === 'dossier') return 'campaign-rose';
    if (item.type === 'focus_topic') return 'campaign-orange';
    if (item.isRecommended) return 'saner';

    return 'other';
});

provide('variant', computedVariant);
provide('item', item);

/*
  Compute Icons based on item type
*/
const computedIcons = computed(() => {
    const hasBookmarkIcon = true;
    const hasCartIcon = item.type === 'product';
    const hasCalendarIcon = item.type === 'event';

    /* Out of MVP but prepared markup */
    const hasLovedIcon = false;
    const hasHelpedIcon = false;

    return {
        hasCalendarIcon,
        hasBookmarkIcon,
        hasLovedIcon,
        hasHelpedIcon,
        hasCartIcon,
    };
});

/*
  Computed Components
*/
const computedComponents = computed(() => {
    const hasImage = item.type !== 'tip';

    return {
        hasImage,
    };
});

/*
  Navigate
*/
const { sendEvent } = useGTM();
const goToProduct = () => {
    const slug = item.slug || item.sb_slug;

    if (slug) {
        switch (item.type) {
        case 'tip':
            break;
        case 'product':
            sendEvent({
                event: 'select_item',
                ecommerce: {
                    value: null,
                    items: [
                        {
                            id: item.id,
                            name: item.name,
                            pharma_code: item.pharma_code,
                            price: item.price,
                            is_sendable: item.is_sendable || false,
                            needs_prescription: item.needs_prescription || false,
                            short_text: item.short_text,
                            description: item.description,
                        },
                    ],
                    item_name: item.name,
                    item_id: item.id,
                    item_type: item.type,
                },
            });
            navigateTo(`../produkte/${slug}`);
            break;
        default:
            navigateTo(`../${slug}`);
            break;
        }
    }
};
</script>

<style lang="scss" scoped>
.shop-product-card__inner {
    flex: 1;
    margin-bottom: 23px;
    aspect-ratio: 1.62;
}

.shop-product-card__flags {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 10px;
    transform: translate3d(1px, -1px, 0);
}
</style>
